<template>
  <list :dataSet="dataSet"></list>
</template>



<script>

import List from "@/views/Insights/List/List";

export default {
  name: "Links",
  components: {List},
  data(){
    return{
      title:'Links',
      dataSet:'links'
    }
  },
  mounted() {
    document.title = this.title;
  }
}
</script>

<style scoped>

</style>